/************************  *************************/

/* 				    Lists - Desktop  	       	   */

/************************  *************************/

main {
    li {
        a {
            text-decoration: underline;
        }
    }
}

.list {

    &--alphabetical {
        li {
            list-style-type: lower-alpha;
        }
    }

    &--checklist {
        list-style: none;

        li {
            margin: 15px auto 0 25px;

            &:before {
                padding-right: 10px;
                font-weight: bold;
            }
        }
    }

    &--correct:before {
        content: '✓';
        color: $color-16;
    }

    &--incorrect:before {
        content: '⨉';
        color: $color-25;
    }

    &--inline {
        display: inline-block;
        margin: 0 auto;
        padding: 0 0 0 40px;
        list-style: none;

        li {
            display: inline-block;
            margin: 0;
            padding-right: 20px;
            list-style-type: none;
            list-style: none;
        }
    }

    &--inline-2 {
        @extend .list--inline;
        
        margin-top: 35px;
    
        &> li {
            margin: 0 auto;
            padding-right: 40px;
        }
    }

}


/************************  *************************/

/* 			       Lists - Mobile  			       */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

    .list {

        &--inline {
            padding: 0;

            li {
                display: block;
                padding: 10px;
            }
        }
    
        &--inline-2 {
            top: 0;
            margin-top: 20px;
        
            &> li {
                padding: 7px 20px;
            }
        }
    
    }
}

@include media("md") {

}

@include media("sm") {

}


/************************  *************************/

/* 			       Lists - Print  			       */

/************************  *************************/


@media print {

}