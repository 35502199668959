/************************  *************************/

/* 	                Fonts - Desktop  		           */

/************************  *************************/

@font-face {
    font-family: 'callunaregular';
    src: url('../fonts/calluna_regular_tabular.eot');
    src: url('../fonts/calluna_regular_tabular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/calluna_regular_tabular.woff2') format('woff2'),
         url('../fonts/calluna_regular_tabular.woff') format('woff'),
         url('../fonts/calluna_regular_tabular.ttf') format('truetype'),
         url('../fonts/calluna_regular_tabular.svg#callunaregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Calluna Sans';
  src:     url('../fonts/CallunaSansRegular.eot');
  src:  url('../fonts/CallunaSansRegular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CallunaSansRegular.woff2') format('woff2'),
            url('../fonts/CallunaSansRegular.woff') format('woff'),
	 	    url('../fonts/CallunaSansRegular.ttf') format('truetype');
  font-style: normal;
  -moz-font-feature-settings: "lnum"; 
  -ms-font-feature-settings: "lnum"; 
  -webkit-font-feature-settings: "lnum"; 
  -o-font-feature-settings: "lnum"; 
  font-feature-settings: "lnum";
}



/************************  *************************/

/* 	                Fonts - Mobile  		           */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

}

@include media("md") {

}

@include media("sm") {

}


/************************  *************************/

/* 	                Fonts - Print  		           */

/************************  *************************/


@media print {

}