/************************  *************************/

/* 	          Formatting - Desktop  	       	   */

/************************  *************************/

// <abbr>	Defines an abbreviation or an acronym
// <address>	Defines contact information for the author/owner of a document/article
// <b>	Defines bold text
// <blockquote>	Defines a section that is quoted from another source
// <cite>	Defines the title of a work
// <code>	Defines a piece of computer code
// <del>	Defines text that has been deleted from a document
// <dfn>	Represents the defining instance of a term
// <em>	Defines emphasized text 
// <i>	Defines a part of text in an alternate voice or mood
// <ins>	Defines a text that has been inserted into a document
// <kbd>	Defines keyboard input
// <mark>	Defines marked/highlighted text
// <meter>	Defines a scalar measurement within a known range (a gauge)
// <pre>	Defines preformatted text
// <progress>	Represents the progress of a task
// <q>	Defines a short quotation
// <s>	Defines text that is no longer correct
// <samp>	Defines sample output from a computer program


// <small>	Defines smaller text

small {
    font-size: $font-size-7;
    font-size: $font-size-7-em;
}


// <strong>	Defines important text
// <sub>	Defines subscripted text
// <sup>	Defines superscripted text
// <template>	Defines a template
// <time>	Defines a date/time
// <u>	Defines text that should be stylistically different from normal text
// <var>	Defines a variable
// <wbr>	Defines a possible line-break


/************************  *************************/
 
/* 	          Formatting - Mobile  	        	   */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

}

@include media("md") {

}

@include media("sm") {

}


/************************  *************************/

/* 	           Formatting - Print  	        	   */

/************************  *************************/


@media print {

}

