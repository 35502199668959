/************************  *************************/

/* 				  SideNav - Desktop  	       	   */

/************************  *************************/


.nav {
    margin: 70px 0 0;

    &__dropdown {
        display: none;
        width: 100%;
        padding: 0 30px;
        line-height: 40px;
        text-align: left;
        text-transform: uppercase;
        color: $color-1;
    }

    & ul {
        & > li {
            & > ul {
                padding-left: 30px;
            }
        }
    }

    li {
        list-style-type: none;
        list-style: none;
        margin-left: 0;
        padding: 0;
        margin-top: 15px;
    
        & > ul {
            display: none;
        }

        &.active > ul {
            display: block;
        }
    }

    a {
        display: block;
        line-height: 28.8px;
        color: $color-14;

        &:hover {
            color: $color-1; 
        }
    
        &.active {
            text-transform: uppercase;
            color: $color-7;
        }
    }

    .sidebar > a {
        border-bottom: 5px solid $color-1; 
        padding-bottom: 2px;
        color: $color-1; 

        &.active {
            color: $color-1; 
            text-transform: uppercase;
        }
    }
}


/************************  *************************/

/* 			     SideNav - Mobile  			       */

/************************  *************************/

@include media("xl") {

}

@include media("lg") {
    
    #leftMenuToggleArrow {
        float: right;
        padding-right: 14px;
        font-family: "laurier_icons" !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        speak: none;
        line-height: inherit;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    
    #leftMenuToggle:hover {
        background: #5b328e;
        color: $color-10;
    }
    
    .sidebar {
        display: block;
    }

    .nav {
        margin: 0 auto;

        ul {
            margin: 0;
        }

        .select_dropdown {
            background: #FFFFFF;
            padding-left: 15px;
            padding-right: 20px;
            height: 40px;
            line-height: 40px;
            text-transform: uppercase;
            cursor: pointer;
            color: $color-1; 

            .icon-toggledown {
                display: inline-block;
            }
        }
    }

    
    #leftMenuToggle {
        display: block;
    }

    .sidebar {
        display: none;
        padding: 15px 0px 15px 30px;
        background: #F6F6F6;
    }

}

@include media("md") {

}

@include media("sm") {

}



/************************  *************************/

/* 			     SideNav - Print  			       */

/************************  *************************/

@media print {

}
