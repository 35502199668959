/************************  *************************/

/* 			   Basic HTML - Desktop  	       	   */

/************************  *************************/


*,
*:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -moz-font-feature-settings: "lnum" !important;
    -ms-font-feature-settings: "lnum" !important;
    -webkit-font-feature-settings: "lnum" !important;
    -o-font-feature-settings: "lnum" !important;
    font-feature-settings: "lnum" !important; 
}


// <html> Defines an HTML document

html {
    height: 100%;
}


// <body> Defines the document's body

body {
    position: relative;
    margin: 0 auto;
    padding: 0;
    line-height: 26px;
    font-size: $font-size-5;
    font-size: $font-size-5-em;
    font-family: 'Calluna Sans';
    font-weight: normal;
    font-style: normal;
    word-wrap: break-word;
    background: white;
    -webkit-print-color-adjust: exact;
    color: $color-3
}


// <h1> to <h6> Defines HTML headings

h1, 
h2, 
h3, 
h4, 
h5, 
h6 {
    font-weight: normal;
    color: $color-3;
}

h2, 
h3, 
h4, 
h5, 
h6 {
    margin: 15px auto 10px;
}

h1,
.h1 {
    margin: 0;
    margin-bottom: 20px;
    line-height: 70px; 
    font-size: $font-size-1;
    font-size: $font-size-1-em;
}

h2,
.h2 { 
    line-height: 34px; 
    font-size: $font-size-2; 
    font-size: $font-size-2-em;
    text-transform: uppercase; 
    color: $color-1; 
}

h3,
.h3 { 
    padding-top: 10px;
    line-height: 32px; 
    font-weight: bold;   
    font-size: $font-size-3; 
    font-size: $font-size-3-em;
}

h4,
.h4 { 
    padding-top: 10px;
    line-height: 30px;
    font-size: $font-size-4; 
    font-size: $font-size-4-em;
}

h5,
.h5 { 
    padding-top: 5px;
    line-height: 30px;
    font-size: $font-size-5;
    font-size: $font-size-5-em;
}

h6,
.h6 { 
    line-height: 30px; 
    font-size: $font-size-6; 
    font-size: $font-size-6-em;
}


// <p> Defines a paragraph

p {
    margin: 6px auto 18px;
}

p a {
    text-decoration: underline;
}

// <br> Inserts a single line break


// <hr>	Defines a thematic change in the content

hr {
    margin: 0;
    border: 0.5px solid $color-3;
}


/************************  *************************/

/* 	           Basic HTML - Mobile  		       */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

}

@include media("md") {

}

@include media("sm") {

}


/************************  *************************/

/* 			   Basic HTML - Print  			       */

/************************  *************************/


@media print {

}

