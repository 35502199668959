/************************  *************************/

/* 	     Styles & Semantics - Desktop  	       	   */

/************************  *************************/


// <style>	Defines style information for a document
// <div>	Defines a section in a document
// <span>	Defines a section in a document
// <header>	Defines a header for a document or section


// <footer>	Defines a footer for a document or section

footer {
    margin-top: 60px;
}


// <main>	Specifies the main content of a document
// <section>	Defines a section in a document
// <article>	Defines an article
// <aside>	Defines content aside from the page content
// <details>	Defines additional details that the user can view or hide
// <dialog>	Defines a dialog box or window
// <summary>	Defines a visible heading for a <details> element
// <data>	Links the given content with a machine-readable translation


/************************  *************************/

/* 	     Styles & Semantics - Mobile  	       	   */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

}

@include media("md") {

}

@include media("sm") {

}


/************************  *************************/

/* 	     Styles & Semantics - Print  	       	   */

/************************  *************************/


@media print {

}

