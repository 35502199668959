/************************  *************************/

/* 	       Scroll To Top - Desktop   		       */

/************************  *************************/


.scroll--top {
    position: fixed;
    display: none;
    right: 5%;
    bottom: 40px;
    z-index: 10000;
    border-radius: 4px;
    background-color: $color-1;

    & > i {
        display: block;
        padding: 10px 15px;
        color: $color-10;

        &:before {
            padding: 0;
        }
    }
}


/************************  *************************/

/* 	        Scroll To Top - Mobile  		       */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

}

@include media("md") {

}

@include media("sm") {

}


/************************  *************************/

/* 		    Scroll To Top - Print  			       */

/************************  *************************/


@media print {

    .scrollToTop {
        display: none !important;
    }

}