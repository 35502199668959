/************************  *************************/

/* 				  Footer - Desktop  	       	   */

/************************  *************************/

.footer--secondary {
    display: block;
    margin: 100px auto 0;
    border: 0;
    border-style: inset;
    padding: 60px 0;
    border-top: 1px solid #e4e4e4;
}


/************************  *************************/

/* 			     Footer - Mobile  			       */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

    .copyright {
        margin-top: 60px;
    }

}

@include media("md") {

}

@include media("sm") {

}



/************************  *************************/

/* 			     Footer - Print  			       */

/************************  *************************/

@media print {

}


