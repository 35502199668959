/************************  *************************/

/* 			     	  Color Variables  	       	   */

/************************  *************************/


$color-1: #330072;
$color-2: #F6F6F6;
$color-3: #444;
$color-4: #F2A900;
$color-5: #995DA7;
$color-6: #db2c39;
$color-7: #6b7036;
$color-8: #0077a2;
$color-9: #585858;
$color-10: #fff;

$color-11: #000;
$color-12: #CD3434;
$color-13: #e6e6e6;
$color-14: #292929;
$color-15: #d3d3d3;

//Alerts
$color-16: #3c763d;
$color-17: #dff0d8;
$color-18: #d6e9c6;

$color-19: #31708f;
$color-20: #d9edf7;
$color-21: #bce8f1;

$color-22: #8a6d3b;
$color-23: #fcf8e3;
$color-24: #faebcc;

$color-25: #a94442;
$color-26: #f2dede;
$color-27: #ebccd1;

$color-27: #E0E0E0;
