/************************  *************************/

/* 				  Buttons - Desktop  	       	   */

/************************  *************************/

.button {
    display: inline-block;
    min-width: 165px;
    margin: 5px 10px;
    padding: 10px 25px;
    text-align: center;
    vertical-align: middle;
    line-height: inherit;
    border: 2px solid transparent;
    text-decoration: none !important;
    cursor: pointer;
    color: $color-10; 
    background-color: transparent;

    &:hover {
        transition: background 0.4s ease;
        text-decoration: none;
        color: $color-10;
        background-color: transparent;
    }

    &--purple-skin {
        background-color: $color-1; 
        
        &:hover {
            border: 2px solid $color-1;
            color: $color-1; 
        }
    }

    &--purple-skin-inverse {
        background-color: transparent; 
        border: 2px solid $color-1;
        color: $color-1;
        
        &:hover {
            background-color: $color-1;
            color: $color-10; 
        }
    }

    &--grey-skin {
        background-color: $color-27;
        color: $color-3; 
        
        &:hover {
            border: 2px solid $color-27;
            color: $color-3;
        }
    }

    &--red-skin {
        background-color: $color-6;
         
        &:hover {
            border: 2px solid $color-6;
            color: $color-6;
        }
    }

    &--green-skin {
        background-color: $color-7;

        &:hover {
            border: 2px solid $color-7;
            color: $color-7;
        }
    }

    &--blue-skin {
        background-color: $color-8;
        
        &:hover {
            border: 2px solid $color-8;
            color: $color-8; 
        }
    }

    &--mauve-skin {
        background-color: $color-5; 

        &:hover {
            border: 2px solid $color-5; 
            color: $color-5; 
        }
    }

    &--gold-skin {
        background-color: $color-4;
        color: $color-11;
        
        &:hover {
            border: 2px solid $color-4;
            color: $color-11;
        }
    }

    &--black-skin {
        background-color: $color-3;

        &:hover {
            border: 2px solid $color-3;
            color: $color-3;
        }
    }

}


/************************  *************************/

/* 			     Buttons - Mobile  			       */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

}

@include media("md") {

}

@include media("sm") {

    .button {
        width: 100%;
        min-width: 100%;
        margin: 0 0 5px 0;
    }

}


/************************  *************************/

/* 			     Buttons - Print  			       */

/************************  *************************/

@media print {

}