// 02. Tools

// The next layer houses your globally available tooling – namely mixins and functions. Any mixin or function that does 
// not need accessing globally should belong in the partial to which it relates. The Tools layer comes after the Settings 
// layer because a mixin may require one of the global settings as a default parameter. Examples of global tools might be 
// gradient mixins, font-sizing mixins and so on.


@function translate-media-condition($c) {
  $condMap: (
    "xl"  : "screen and (max-width: #{$xl})",
    "lg"  : "screen and (max-width: #{$lg})",
    "md"  : "screen and (max-width: #{$md})",
    "sm"  : "screen and (max-width: #{$sm})"   
  );
  @return map-get( $condMap, $c );
}

// The mdia mixin
@mixin media($arg) {
    
  @media #{translate-media-condition($arg)}  { @content; }

}
