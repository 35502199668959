/************************  *************************/

/* 			     	  Font Variables  	       	   */

/************************  *************************/

// Base body font
$font-size-main: 18;

$font-size-1: 50px;
$font-size-1-em: (50 / 18) * 1em;

$font-size-2: 24px;
$font-size-2-em: (24 / 18) * 1em;

$font-size-3: 22px;
$font-size-3-em: (22 / 18) * 1em;

$font-size-4: 20px;
$font-size-4-em: (20 / 18) * 1em;

$font-size-5: 18px;
$font-size-5-em: 1em;

$font-size-6: 16px;
$font-size-6-em: (16 / 18) * 1em;

$font-size-7: 14px;
$font-size-7-em: (14 / 18) * 1em;
