/************************  *************************/

/* 	                Icons - Desktop  		           */

/************************  *************************/


@font-face {
  font-family: "laurier_icons";
  src:url("../fonts/laurier_icons.eot");
  src:url("../fonts/laurier_icons.eot?#iefix") format("embedded-opentype"),
    url("../fonts/laurier_icons.woff") format("woff"),
    url("../fonts/laurier_icons.ttf") format("truetype"),
    url("../fonts/laurier_icons.svg#laurier_icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon__"]:before,
[class*=" icon__"]:before {
  padding-right: 10px;
  font-family: "laurier_icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon {
  &__calendar:before {
    content: "a";
  }

  &__close:before {
    content: "b";
  }
  
  &__email:before {
    content: "c";
  }
  
  &__facebook:before {
    content: "d";
  }
  
  &__instagram:before {
    content: "e";
  }
  
  &__leaf:before {
    content: "f";
  }
  
  &__linkedin:before {
    content: "g";
  }
  
  &__menu:before {
    content: "h";
  }
  
  &__search:before {
    content: "i";
  }
  
  &__share:before {
    content: "j";
  }
  
  &__toggledown:before {
    content: "k";
  }
  
  &__toggleleft:before {
    content: "l";
  }
  
  &__toggleright:before {
    content: "m";
  }
  
  &__toggleup:before {
    content: "n";
  }
  
  &__twitter:before {
    content: "o";
  }
  
  &__video:before {
    content: "p";
  }
  
  &__vimeo:before {
      content: "\56";
  }
  
  &__wordpress:before {
      content: "\57";
  }
  
  &__youtube:before {
      content: "\59";
  }
  
  &__file-word:before {
      content: "\71";
  }
  
  &__box-add:before {
    content: "\72";
  }
  
  &__checkbox-checked:before {
    content: "\73";
  }
  
  &__checkbox-unchecked:before {
    content: "\74";
  }
  
  &__connection:before {
    content: "\75";
  }
  
  &__display:before {
    content: "\76";
  }
  
  &__download:before {
    content: "\77";
  }
  
  &__download2:before {
    content: "\78";
  }
  
  &__earth:before {
    content: "\79";
  }
  
  &__envelop:before {
    content: "\7a";
  }
  
  &__feed2:before {
    content: "\41";
  }
  
  &__file-music:before {
    content: "\42";
  }
  
  &__file-pdf:before {
    content: "\43";
  }
  
  &__google:before {
    content: "\44";
  }
  
  &__google-plus:before {
    content: "\45";
  }
  
  &__headphones:before {
    content: "\46";
  }
  
  &__home3:before {
    content: "\47";
  }
  
  &__location:before {
    content: "\48";
  }
  
  &__map2:before {
    content: "\49";
  }
  
  &__music:before {
    content: "\4a";
  }
  
  &__pinterest:before {
    content: "\4b";
  }
  
  &__play:before {
    content: "\4c";
  }
  
  &__podcast:before {
    content: "\4d";
  }
  
  &__printer:before {
    content: "\4e";
  }
  
  &__quotes-left:before {
    content: "\4f";
  }
  
  &__quotes-right:before {
    content: "\50";
  }
  
  &__radio-unchecked:before {
    content: "\51";
  }
  
  &__reddit:before {
    content: "\52";
  }
  
  &__soundcloud:before {
    content: "\53";
  }
  
  &__tumblr:before {
    content: "\54";
  }
  
  &__twitch:before {
    content: "\55";
  }
}


/************************  *************************/

/* 	                 Icons - Mobile  		           */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

}

@include media("md") {

}

@include media("sm") {

}


/************************  *************************/

/* 	                 Icons - Print  		           */

/************************  *************************/


@media print {

}