/************************  *************************/

/* 				  Alerts - Desktop  	       	   */

/************************  *************************/


.alert {
    margin-bottom: 20px;
    padding: 15px 35px 15px 15px;
    border: 1px solid transparent;
    border-radius: 4px;
    
    &__close {
        position: relative;
        float: right;
        top: -2px;
        right: -21px;
        padding: 0;
        border: 0;
        line-height: 1;
        font-size: 22px;
        font-weight: 700;
        text-shadow: 0 1px 0 $color-10;
        opacity: .2;
        color: $color-11;
        background: transparent;

        &:hover {
            opacity: 1;
        }
    }

    &--success {
        border-color: $color-18;
        color: $color-16;
        background-color: $color-17;
    }
    
    &--info {
        border-color: $color-21;
        color: $color-19;
        background-color: $color-20;
    }
    
    &--warning {
        border-color: $color-24;
        color: $color-22;
        background-color: $color-23;
    }
    
    &--danger {
        border-color: $color-27;
        color: $color-25;
        background-color: $color-26;
    }
    
}


/************************  *************************/

/* 			      Alerts - Mobile  			       */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

}

@include media("md") {

}

@include media("sm") {

}


/************************  *************************/

/* 			      Alerts - Print  			       */

/************************  *************************/


@media print {

}