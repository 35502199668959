/************************  *************************/

/* 	      Forms & Inputs - Desktop  	       	   */

/************************  *************************/


button, 
input, 
select, 
textarea {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0px;
    font-family: 'Calluna Sans';
    color: $color-9;
}


// <form>	Defines an HTML form for user input


// <input>	Defines an input control
 
input:not([type='checkbox']):not([type="radio"]):not([type='submit']):not([type='button']), 
textarea,
select {
    width: 100%;
    padding: 9px;
    border: 1px solid #e8e8e8;
    transition: box-shadow 0.45s, border-color 0.45s ease-in-out;
    font-size: $font-size-5;
    font-size: $font-size-5-em;
}

input:not([type='checkbox']):not([type="radio"]):not([type='submit']):not([type='button']):focus,
textarea:focus,
select:focus {
    outline: none;
    border: 1px solid $color-4 !important;
}

input:not([type="submit"]):not([type='button']):read-only {
    background-color: transparent !important;
    border: 0 !important;
    font-size: 1em !important;
    font-weight: bold;
}

input:focus::-webkit-input-placeholder, 
textarea:focus::-webkit-input-placeholder {
    color: transparent;
}

input[type=number]{
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button, 
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

// <textarea>	Defines a multiline input control (text area)

textarea {
    overflow: auto;
    max-width: 100%;
    height: auto;
}


// <button>	Defines a clickable button


// <select>	Defines a drop-down list
// <optgroup>	Defines a group of related options in a drop-down list
// <option>	Defines an option in a drop-down list

option {
    color: $color-3;

    &[value=""][disabled] {
        display: none;
    }
}


// <fieldset>	Groups related elements in a form

fieldset {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    line-height: 33px;
    border: none;

    label {
        display: inline-block;
        color: $color-3;
        font-size: $font-size-5;
        font-size: $font-size-5-em;
    }
}

// <label>	Defines a label for an <input> element
// <legend>	Defines a caption for a <fieldset> element

legend {
    display: block;
    margin: 5px 0 10px;
    padding: 0;
    color: $color-1; 
    font-weight: 400;
    font-size: $font-size-3;
    font-size: $font-size-3-em;
}


// <datalist>	Specifies a list of pre-defined options for input controls
// <output>	Defines the result of a calculation


/************************  *************************/

/* 	       Forms & Inputs - Mobile   	       	   */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

}

@include media("md") {

}

@include media("sm") {

}


/************************  *************************/

/* 	       Forms & Inputs - Print  	        	   */

/************************  *************************/


@media print {

}

