/************************  *************************/

/* 	                States - Desktop  	       	   */

/************************  *************************/


// :focus states
*:focus {
    outline: $color-1 dotted 2px;
}

/************************  *************************/

/* 	                States - Mobile  	       	   */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

}

@include media("md") {

}

@include media("sm") {

}


/************************  *************************/

/* 	                States - Print  	       	   */

/************************  *************************/


@media print {

}

