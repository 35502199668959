/************************  *************************/

/* 	              Images - Desktop  	       	   */

/************************  *************************/


// <img>	Defines an image
img {
    max-width: 100%;
}

// <map>	Defines a client-side image-map
// <area>	Defines an area inside an image-map
// <canvas>	Used to draw graphics, on the fly, via scripting (usually JavaScript)
// <figcaption>	Defines a caption for a <figure> element
// <figure>	Specifies self-contained content
// <picture>	Defines a container for multiple image resources


/************************  *************************/

/* 	              Images - Mobile  	        	   */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

}

@include media("md") {

}

@include media("sm") {

}


/************************  *************************/

/* 	               Images - Print  	        	   */

/************************  *************************/


@media print {

}

