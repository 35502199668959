/************************  *************************/

/* 			  Font Resize - Desktop  	       	   */

/************************  *************************/

header .font-resize {
    & > a {
        margin-left: 10px;
    }
}

.fontXL {
    font-size: $font-size-3;
}

.fontL {
    font-size: $font-size-4;
}

.fontM {
    font-size: $font-size-5;
}

/************************  *************************/

/* 			  Font Resize - Mobile  	       	   */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

    .fontXL {
        font-size: $font-size-4;
    }

    .fontL {
        font-size: $font-size-5;
    }

    .fontM {
        font-size: $font-size-6;
    }

}

@include media("md") {

}

@include media("sm") {

    .fontXL {
        font-size: $font-size-5;
    }

    .fontL {
        font-size: $font-size-6;
    }

    .fontM {
        font-size: $font-size-7;
    }

}



/************************  *************************/

/* 			  Font Resize - Print  	       	   */

/************************  *************************/

@media print {

}