/************************  *************************/

/* 	                Lists - Desktop  	       	   */

/************************  *************************/


// <ul>	Defines an unordered list

ul {
    padding: 0;
}


// <ol>	Defines an ordered list

ol {
    padding: 0;
    
    & > li {
        list-style-type: decimal;
    }
}

// <li>	Defines a list item

li {
    margin: 15px auto 0 40px;

    &> p {
        margin: 0 auto;
    }
}


// <dl>	Defines a description list
// <dt>	Defines a term/name in a description list
// <dd>	Defines a description of a term/name in a description list
// <menu>	Defines a list/menu of commands
// <menuitem>	Defines a command/menu item that the user can invoke from a popup menu


/************************  *************************/

/* 	                Lists - Mobile  	       	   */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

}

@include media("md") {

}

@include media("sm") {

}


/************************  *************************/

/* 	                 Lists - Print  	       	   */

/************************  *************************/


@media print {

}

