/************************  *************************/

/* 	                Forms - Desktop  		       */

/************************  *************************/


.form {
    &__row {
        margin-bottom: 30px;
    }

    &__radio--group {
        @extend .inline-block;

        margin-right: 15px;
    }

    &__mandatory {
        color: $color-12 !important;
    }

    &_label {
        display: block;
        margin: 5px 0 10px;
        line-height: 33px;
        font-size: 22px;
        font-size: 1.22222em;
        color: $color-1;
    }

    &__error {
        border: 1px solid $color-25 !important;
    }
    
}


/************************  *************************/

/* 	                Forms - Mobile  		       */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

}

@include media("md") {

}

@include media("sm") {

}


/************************  *************************/

/* 	                Forms - Print  		       */

/************************  *************************/


@media print {

}