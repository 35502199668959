/************************  *************************/

/* 				     Tab - Desktop  	       	   */

/************************  *************************/


.tab {
    &__button {
        padding: 14px 16px;
        -webkit-transition: .3s;
        transition: .3s;
        color: $color-1; 
        background-color: $color-2;

        &:not(.active) {
            background-color: $color-1; 
            color: $color-10;
        }

        &:not(.active):hover,
        &:focus {
            color: $color-1;
            background-color: $color-15;
        }

    }

    &__content {
        display: none;
        min-height: 200px;
        padding: 20px 25px;
        background-color: $color-2;
    }
}


/************************  *************************/

/* 			         Tab - Mobile  			       */

/************************  *************************/

@include media("xl") {

}

@include media("lg") {

}

@include media("md") {

}

@include media("sm") {

}



/************************  *************************/

/* 			         Tab - Print  			       */

/************************  *************************/

@media print {

}