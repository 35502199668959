/************************  *************************/

/* 				  Objects - Desktop  	       	   */

/************************  *************************/

// Floats
.float-right,
.float-right-mobile {
    float: right !important;
}

.float-left {
    float: left;
}

// Displays
.inline-block {
    display: inline-block;
    vertical-align: top;
}

// Widths
.width-auto {
    width: auto !important;
}

// Cols
.col-35 {
    display: inline-block;
    width: 300px;
}

.col-25 {
    width: 25%;
    float: left;
}

.col-50 {
    width: 50%;
    float: left;
}

.col-75 {
    width: 75%;
    float: left;
}

.col {
    display: block;
    overflow: hidden;
    width: 100%;

    .padding-right {
        padding-right: 20px;
    }

    .padding-left {
        padding-left: 20px;
    }
}

// Margin
.margin--10 {
    margin: 10px auto;
}

.margin-right-60 {
    margin-right: 60px;
}

.margin-right-100 {
    margin-right: 100px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin--none {
    margin: 0;
}

// Padding
.padding-left-30 {
    padding-left: 30px;
}

.padding--none {
    padding: 0;
}

// Clear
.clear-left {
    clear: left;
}

// Styled headings
.style-h1 {
    display: inline-block;
    margin-top: 65px;
    line-height: 48px;
    font-size: $font-size-1;
    font-size: $font-size-1-em;
    color: $color-1; 
}

// Messages
.error-text {
    color: $color-25;
}

.success-text {
    color: $color-16;
}

// Text
.align-center {
    text-align: center;
}

// Element Structure
.structure {
    &--one {
        padding: 30px 20px;
        margin: 6px auto 18px;
    }
}



/************************  *************************/

/* 			     Objects - Mobile  			       */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

    // Floats
    .float-left, .float-right {
        float: none;
    }

    // Col
    .col-25, 
    .col-35,
    .col-75 {
        width: 100%;
    }

    // Margin
    .margin-bottom-40 {
        margin-bottom: 0px;
    }

}

@include media("md") {

}

@include media("sm") {

}



/************************  *************************/

/* 			     Objects - Print  			       */

/************************  *************************/

@media print {

}



.background__grey {
    background: $color-2;
}

.background__purple {
    background: $color-1;
}



.padding__1 {
    padding: 30px 30px 40px 55px;
}


.row {
    display: block;
    width: 100%;
    box-sizing: border-box;
    
}

.row_standard {
    max-width: 1230px;
    margin: 0 auto;
    padding: 0 30px;
}

.spacer_1 {
    display: inline-block;
    width: 100%;
    margin: 20px;
}

.margin-top-style-1 {
    margin-top: 40px;
}

button {
    display: inline-block;
    margin-bottom: 0px;
    font-size: 100%;
    font-weight: normal;
    line-height: 1.42857;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    padding: 12px 40px;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    transition: all 0.2s ease-out 0s;
    text-decoration: none;
}

.font-size-small {
    font-size: $font-size-7;
    font-size: $font-size-7-em;
}








/************************  *************************/

/* 			     Footer - Mobile  			       */

/************************  *************************/

@media screen and (max-width:1199px) {

}

@media screen and (max-width:1024px) {

    .margin-right-100 {
        margin-right: 0;
    }

    .margin-top-style-1 {
        margin-top: 20px;
    }

    .padding__1 {
        padding: 30px 0 40px;
    }

    .row_standard {
        padding: 0;
    }

    .mobile-padding {
        box-sizing: border-box;
        padding: 0 30px;
    }
}

@media screen and (max-width:991px) {

}

@media screen and (max-width:767px) {


}


/************************  *************************/

/* 			     Footer - Print  			       */

/************************  *************************/

@media print {

}
