/************************  *************************/

/* 	                Links - Desktop  	       	   */

/************************  *************************/


// <a>	Defines a hyperlink

a {
    text-decoration: none;
    cursor: pointer;
    color: $color-1;
}

a:hover {
    text-decoration: underline;
    color: $color-1; 
}


// <link>	Defines the relationship between a document and an external resource (most used to link to style sheets)
// <nav>	Defines navigation links


/************************  *************************/

/* 	                Links - Mobile  	       	   */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

}

@include media("md") {

}

@include media("sm") {

}


/************************  *************************/

/* 	                Links - Print  	        	   */

/************************  *************************/


@media print {

}

