/************************  *************************/

/* 		 Special Interest - Desktop  	     	   */

/************************  *************************/


.specialInterest {
    background-color: #6B7036;
    color: #fff;
    padding: 25px;
    margin: 20px auto 40px;

    .title {
        text-transform: uppercase;
    }

    p.subHead {
        font-weight: bold;
    }

    .contact-info-field {
        padding-bottom: 20px;
    }

    a {
        color: #fff;
        text-decoration: underline;
    }

    .socialMedia {
        margin-bottom: 20px;
        
        p {
            margin: 0;
            margin-bottom: 5px; 
        }
    }
}

.specialLinks {
    .button {
        width: 100%;
        margin: 5px 0;
    }
}


/************************  *************************/

/* 		Special Interest - Mobile  			       */

/************************  *************************/

@include media("xl") {

}

@include media("lg") {

}

@include media("md") {

}

@include media("sm") {

}



/************************  *************************/

/* 		Special Interest - Print  			       */

/************************  *************************/

@media print {  

}