/************************  *************************/

/* 				  Tables - Desktop 				   */

/************************  *************************/

.dataTable {
	display: none;
}

.dataTables_wrapper {

	.dataTable {
		display: table;
	}

}



/************************  *************************/

/* 	            	Tables - Mobile  		       */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

}

@include media("md") {

}

@include media("sm") {

}


/************************  *************************/

/* 			  	   Tables - Print  			       */

/************************  *************************/


@media print {

}