/************************  *************************/

/* 			  Datepicker - Desktop 	    		   */

/************************  *************************/

.datepicker-container {

    .datepicker-panel {
        color: $color-1;

        &>ul>li.highlighted {
            color: $color-10;
            background-color: $color-5;
        }

        &>ul>li:hover,
        &>ul>li.picked, 
        &>ul>li.picked:hover {
            color: $color-10;
            background-color: $color-1;
        }
    }

}



/************************  *************************/

/* 	           Datepicker - Mobile  		       */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {
	
}

@include media("md") {

}

@include media("sm") {

}


/************************  *************************/

/* 			   Datepicker - Print  			       */

/************************  *************************/


@media print {

}