/************************  *************************/

/* 			    Accordion - Desktop  	       	   */

/************************  *************************/


.accordion {
    margin: 20px auto;
    
    &__header {
        width: 100%;
        min-height: 66px;
        padding: 20px;
        border: 1px solid $color-10;
        white-space: normal;
        text-align: left;
        text-transform: uppercase;
        color: $color-10;
        background: #330072;

        &:hover,
        &:focus {
            color: $color-1;
            background-color: $color-15;
        }
    }

    &__body {
        display: none;
        padding: 30px 20px;
        border: 1px solid transparent;
        background: $color-2;
    }

}


/************************  *************************/

/* 		       Accordion - Mobile  			       */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

}

@include media("md") {

}

@include media("sm") {

}


/************************  *************************/

/* 			    Accordion - Print  			       */

/************************  *************************/


@media print {

}