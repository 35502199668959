/************************  *************************/

/* 			  Breadcrumb - Desktop  	       	   */

/************************  *************************/


.breadcrumbs {
    display: inline-block;
    overflow: hidden;
    margin: 0;
    border: none;
    list-style: none;
    background: none;

    li {
        display: inline-block;
        margin: 15px auto 0;
    }

    & > *:first-child:before {
        content: " ";
        margin: 0;
    }

    & > *:before {
        content: "/";
        color: #444;
        margin: 0 0.75em;
        position: relative;
        top: 1px;
    }
}


/************************  *************************/

/* 		      Breadcrumb - Mobile  			       */

/************************  *************************/

@include media("xl") {

}

@include media("lg") {

    .breadcrumbs {
        display: none;
    }

}

@include media("md") {

}

@include media("sm") {

}



/************************  *************************/

/* 			   Breadcrumb - Print  			       */

/************************  *************************/

@media print {

}






