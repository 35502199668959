/************************  *************************/

/* 			   dataTable - Desktop 	    		   */

/************************  *************************/


.dataTables_wrapper {
	display: none;
	overflow-y: auto;
	margin: 20px auto 50px;

	table.dataTable {
		border-bottom: 0;
		border-spacing: 5px 0;

		thead {
			th, 
			td {
				vertical-align: bottom;
				padding: 8px 10px;
				border-bottom: 2px solid #330072;
			}

			.sorting_desc:after {
				margin-left: 5px;
				font-size: inherit;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				width: 1.28571429em;
				text-align: center;
				font-family: "laurier_icons" !important;
				font-style: normal !important;
				font-weight: normal !important;
				font-variant: normal !important;
				text-transform: none !important;
				line-height: inherit;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				content: "n";
			}
		
			.sorting_asc:after {
				margin-left: 5px;
				font-size: inherit;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				width: 1.28571429em;
				text-align: center;
				font-family: "laurier_icons" !important;
				font-style: normal !important;
				font-weight: normal !important;
				font-variant: normal !important;
				text-transform: none !important;
				line-height: inherit;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				content: "k";
			}
		}

		tr.even, 
		tr.alt, 
		tr:nth-of-type(even) {
			background: #f9f9f9;
		}

		td:before {
			display: none;
		}
	
	}

	label {
		text-align: left;
		color: $color-1 !important;
	}

	.top {
		overflow: hidden;
		margin-bottom: 18px;
	}
	
	.bottom {
		overflow: hidden;
		margin-top: 17px;
		padding: 2px 0;
	}

	.dataTables_info {
		color: $color-1 !important;
	}

	.dataTables_filter input {
		margin-left: 0;
	}

	.dataTables_paginate {
		.paginate_button,
		& > a, 
		& > span > a {
			margin-left: -1px!important;
			padding: 6px 12px!important;
			border: 1px solid #ddd!important;
		}

		.paginate_button.current,
		.paginate_button.current,
		.paginate_button.current:hover,
		.paginate_button:hover {
			color: $color-10 !important;
			background: rgb(51, 0, 114) !important;
		}

		.paginate_button.disabled:hover {
			background: transparent !important;
		}
	}
}


/************************  *************************/

/* 	            dataTable - Mobile  		       */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

	.dataTables_wrapper {
		table.dataTable {
			box-sizing: border-box;
			display: block;
			margin: 0 auto !important;
			border: none;
			font-weight: normal !important;

			th, 
			td {
				box-sizing: border-box;
			}

			thead, 
			tbody, 
			th, 
			td, 
			tr, 
			td:before {
				display: block;
			}

			thead tr {
				position: absolute;
				top: -9999px;
				left: -9999px;
			}

			tr {
				background: transparent !important;

				&:nth-of-type(odd) {
					background-color: transparent;
				}

				td {
					position: relative;
					text-align: right !important;
					overflow: hidden;
					width: 100% !important;
					max-width: 100% !important;
					white-space: normal !important;

					&:nth-of-type(odd) {
						background-color: $color-2;
					}

					&:first-child {
						border-top: none!important;
						font-weight: 700!important;
						color: $color-1;
						background-color: transparent;
					}

					&:last-child {
						border-bottom: none !important;
					}

					&:before {
						position: relative;
						float: left;
						width: 50%;
						text-align: left;
					}
				}
			}
		}

		.dataTables_paginate {
			margin-top: 1em;
			padding: 2px;
		}
	} 
	
}

@include media("md") {

}

@include media("sm") {

}


/************************  *************************/

/* 			   dataTable - Print  			       */

/************************  *************************/


@media print {

}