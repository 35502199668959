/************************  *************************/

/* 	            Skip to main content  		       */

/************************  *************************/

#skip-to-content {
    padding: 1em;
    position: absolute;
    top: -60px;
    left: 1em;
    z-index: 1000;
    color: $color-10;
    font-size: 1rem;
}

#skip-to-content:focus {
    position: absolute;
    top: 0;
    background: $color-1;
}
