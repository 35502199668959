/************************  *************************/

/* 				 Sticky Footer - Desktop  		    		   */

/************************  *************************/


.site {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	height: 100%;

	&__header,
	&__footer {
		-webkit-box-flex: 0;
		-ms-flex: none;
		flex: none;
	}
		
	&__content {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-flex: 1;
		-ms-flex: 1 0 auto;
		flex: 1 0 auto;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		width: 100%;
		min-height: 500px;
	}
}

/************************  **************************/

/* 	        Sticky Footer - Mobile  		            */

/************************  **************************/


@include media("xl") {

}

@include media("lg") {

}

@include media("md") {

}

@include media("sm") {

}


/************************  *************************/

/* 			  	Sticky Footer - Print  			           */

/************************  *************************/


@media print {

}