/************************  *************************/

/* 				    Skins - Desktop  	       	   */

/************************  *************************/

.bg {
    color: $color-10; 

    &--purple-skin {
        background-color: $color-1; 
    }

    &--grey-skin {
        background-color: $color-27;
        color: $color-3;
    }

    &--red-skin {
        background-color: $color-6;
    }

    &--green-skin {
        background-color: $color-7;
    }

    &--blue-skin {
        background-color: $color-8;
    }

    &--mauve-skin {
        background-color: $color-5; 
    }

    &--gold-skin {
        background-color: $color-4;
    }

    &--black-skin {
        background-color: $color-3;
    }
}

/************************  *************************/

/* 			       Skins - Mobile  			       */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

}

@include media("md") {

}

@include media("sm") {

}


/************************  *************************/

/* 			       Skins - Print  			       */

/************************  *************************/


@media print {

}