/************************  *************************/

/* 	                Tables - Desktop  	       	   */

/************************  *************************/


// <table>	Defines a table

table {
    box-sizing: content-box;
    width: 100%;
    margin: 20px auto 50px;
    border-collapse: separate;
    border-spacing: 5px 0px;
    font-size: $font-size-7;
    font-size: $font-size-7-em;
    text-align: left;
}


// <caption>	Defines a table caption
// <th>	Defines a header cell in a table

table > thead th, 
table > tbody td {
    box-sizing: content-box;
    overflow: hidden;
    padding: 8px 10px;
    border-bottom: 0;
}

table > thead th {
    border-bottom: 2px solid $color-1;
    
}


// <tr>	Defines a row in a table

table tr.even, 
table tr.alt, 
table tr:nth-of-type(even) {
    background: $color-2;
}

// <td>	Defines a cell in a table
table > tbody td {
    border-top: 1px solid $color-1;

    &:hover {
        background: $color-2;
    }
}
// <thead>	Groups the header content in a table

table thead {
    font-size: 18px;
    color: $color-1;
}


// <tbody>	Groups the body content in a table
// <tfoot>	Groups the footer content in a table
// <col>	Specifies column properties for each column within a <colgroup> element
// <colgroup>	Specifies a group of one or more columns in a table for formatting


/************************  *************************/

/* 	                Tables - Mobile  	       	   */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

}

@include media("md") {

}

@include media("sm") {

}


/************************  *************************/

/* 	                Tables - Print  	       	   */

/************************  *************************/


@media print {

}

