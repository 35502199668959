/************************  *************************/

/* 	              Modals - Desktop  	           */

/************************  *************************/


.modal {
    position: fixed;
    display: none;
    overflow: auto;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 10vh;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);

    &__content {
        -webkit-animation-name: animatetop;
        -webkit-animation-duration: 0.4s;
        animation-name: animatetop;
        animation-duration: 0.4s;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
        background-color: $color-10;
    }

    &__header {
        text-align: right;
        color: $color-10;
        background-color: $color-1;
    }
    
    &__body {
        padding: 50px;
    }
    
    &__footer {
        padding: 2px 16px;
        color: $color-10;
        background-color: $color-1;
    }
    
    &__close {
        display: inline-block;
        padding: 15px 30px;
        font-size: 28px;
        font-weight: bold;
        color: $color-10;
        background-color: $color-1;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    @-webkit-keyframes animatetop {
        from {top:-300px; opacity:0} 
        to {top:0; opacity:1}
    }
    
    @keyframes animatetop {
        from {top:-300px; opacity:0}
        to {top:0; opacity:1}
    }
}


/************************  *************************/

/* 	              Modals - Mobile  	               */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

}

@include media("md") {

}

@include media("sm") {

}


/************************  *************************/

/* 	               Modals - Print  		           */

/************************  *************************/


@media print {

}