/************************  *************************/

/* 			  NProgress - Desktop 	    		   */

/************************  *************************/


#nprogress .bar {
    background: $color-4;
}

#nprogress .spinner-icon {
    border-top-color: $color-4;
    border-left-color: $color-4;
}


/************************  *************************/

/* 	            NProgress - Mobile  		       */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {
	
}

@include media("md") {

}

@include media("sm") {

}


/************************  *************************/

/* 			   NProgress - Print  			       */

/************************  *************************/


@media print {

}