/************************  *************************/

/* 				  Header - Desktop  	       	   */

/************************  *************************/


.header_nav {
    width: 100%;
    padding: 15px 30px;
    text-align: right;

    a {
        color: $color-10;
        margin-left: 30px;

        &:focus {
            outline: $color-10 dotted 2px;
        }
    }
}

.logo-desktop,
.logo-mobile,
.logo-footer {
    max-width: 435px;
    width: 100%;
    max-height: 170px;
    margin: 20px 0;
}

.logo-footer {
    width: 100%;
    max-width: 300px;
    margin: 0;
}

.logo-mobile {
    display: none;
}

#mobile-nav {
    display: none;
}

.menu-expand {
    display: none;
    background-color: $color-1; 
    width: 100%;
    text-align: center;
    color: $color-10;
    text-transform: uppercase;
    padding: 7px;
}

.menu-expand.opened i {
    -ms-transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
}

#mobile-nav > .icon {
    font-size: $font-size-2;
    font-size: $font-size-2-em;
}


/************************  *************************/

/* 			     Header - Mobile  			       */

/************************  *************************/


@include media("xl") {

}

@include media("lg") {

    .logo-mobile {
        display: block;
    }

    .logo-desktop {
        display: none;
    }


    header hr {
        display: none;
    }

    header .page-title {
        display: block;
        overflow: hidden;
        margin-top: 0;
        padding: 0 25px;
        background-color: $color-13;

        .style-h1 {
            display: inline-block;
            margin: 0;
            line-height: 60px;
            color: $color-1; 
        }
    }

    #mobile-nav {
        display: block;
        float: right;
        color: $color-1; 
        cursor: pointer;
        margin-top: 20px;
    }

    .menu-expand {
        display: inline-block;
    }

    .menu-expand i {
        display: inline-block;
        width: 100%;
        color: $color-10;
        font-size: $font-size-2;
        font-size: $font-size-2-em;
        -ms-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .header_nav a {
        color: $color-10;
        line-height: 60px;
        display: block;
        text-align: left;
        text-transform: uppercase;
        line-height: 30px;
        margin: 0;
    }

    .menu_accord {
        padding-top: 10px;
    }

    .accordion_header .menu_accord {
        max-height: 0;
        overflow: hidden;
        float: none;
        width: 100%;
        -webkit-transition: max-height 0.5s;
        -moz-transition: max-height 0.5s;
        transition: max-height 0.5s;
    }

    a, a:visited {
        outline: 0;
        transition: all 0.2s ease-out 0s;
    }

    .menu_accord.menuOpen {
        max-height: 1000px;
        -webkit-transition: max-height 0.5s;
        -moz-transition: max-height 0.5s;
        transition: max-height 0.5s;
    }

    #utilityMenu {
        display: none;
    }

    .header_nav {
        min-height: auto;
    }
    
}

@include media("md") {

}

@include media("sm") {

}


/************************  *************************/

/* 			     Header - Print  			       */

/************************  *************************/


@media print {

}





